import { NgModule } from '@angular/core';
import { PassedInitialConfig, provideAuth } from 'angular-auth-oidc-client';

import { environment } from 'environments/environment';

import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './components/login/login.component';
import { LoginRedirectComponent } from './components/login-redirect/login-redirect.component';

export const oidcConfig: PassedInitialConfig = {
  config: {
    authority: environment.fusionAuth.authority,
    redirectUrl: `${window.location.origin}/auth/oauth-redirect`,
    postLogoutRedirectUri: window.location.origin,
    clientId: environment.fusionAuth.clientId,
    scope: 'openid offline_access',
    responseType: 'code',
    silentRenew: true,
    useRefreshToken: true,
    renewTimeBeforeTokenExpiresInSeconds: 21600,
    logLevel: environment.fusionAuth.logLevel,
    triggerAuthorizationResultEvent: true,
    triggerRefreshWhenIdTokenExpired: false,
    disableIatOffsetValidation: true,
  },
};

@NgModule({
  imports: [AuthRoutingModule],
  declarations: [LoginRedirectComponent, LoginComponent],
  providers: [provideAuth(oidcConfig)],
})
export class AuthModule { }
